import React from 'react'
import { Box } from 'theme-ui'
import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import { ResourceTabs } from '@components/Resources'
import { ResourceProvider } from '@components/Resources'

const Resources = () => {
  return (
    <Layout page="resources">
      <SEO title="Resources" />
      <Box
        sx={{
          m: [3, 5],
        }}
      >
        <ResourceProvider>
          <ResourceTabs />
        </ResourceProvider>
      </Box>
    </Layout>
  )
}

export default Resources
